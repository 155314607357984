import React from "react";
import logo from "../assets/images/logo.png";
import logo_nombre from "../assets/images/logo_nombre.png";

const EnConstruccion = () => {

return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
            <img
                src={logo_nombre}
                alt="Pezzi Inmobiliaria"
                className={`hidden sm:block mx-auto mb-8 max-w-[350px]`}
            />
            <img
                src={logo}
                alt="Pezzi Inmobiliaria"
                className="sm:hidden mx-auto mb-8 max-w-[200px]"
            />
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
                Sitio en Construcción
            </h1>
            <p className="text-gray-600 mb-8">
                Estamos trabajando duro para mejorar nuestro sitio web. Volveremos
                pronto con una experiencia mejorada.
            </p>
            <div className="animate-pulse flex justify-center">
                <div className="h-2 w-2 bg-blue-600 rounded-full mx-1"></div>
                <div className="h-2 w-2 bg-blue-600 rounded-full mx-1"></div>
                <div className="h-2 w-2 bg-blue-600 rounded-full mx-1"></div>
            </div>
        </div>
    </div>
);
};

export default EnConstruccion;
